import store from '@/store'
import { ref } from '@vue/composition-api'

export default function useUsersView() {


  const fetchOne = async (id) => {
    try {
      const res = await store
      .dispatch('users/fetchOne', id)
      const data = ref([])
      data.value = res.map(ele => {
        return JSON.parse(ele['listusers']).users
      })
        return data.value[0][0]
    } catch (error) {
    }
  }
  const update = async payload => {
    try {
      const response = await store.dispatch(`users/update`, payload)
      return response
    } catch (error) {

    }
  }

  const resolveUserRoleVariant = role => {
    if (role === 'team_member') return 'secondary'
    if (role === 'committee') return 'warning'
    if (role === 'project_manager') return 'info'
    if (role === 'resource_manager') return 'error'
    if (role === 'sponsor') return 'success'
    if (role === 'admin') return 'primary'
    if (role === 'auditor') return 'accent'

    return 'primary'
  }

  const resolveUserStatusVariant = status => {
    if (status) return 'success'
    else return 'error'
  }

  const roleOptions = [
    { text: 'Administrador', value: 'admin', level: 0 },
    { text: 'Coordinador', value: 'project_manager', level: 1 },
    { text: 'Formulador', value: 'resource_manager', level:  2},
    { text: 'Comité Evaluador', value: 'commitee', level: 3 },
    { text: 'Auditor', value: 'auditor', level:  4},
    { text: 'Patrocinador', value: 'sponsor', level:  5},
    { text: 'Miembro', value: 'team_member', level:  6},
  ]


  return {
    fetchOne,
    resolveUserRoleVariant,
    resolveUserStatusVariant,
    roleOptions,
    update
  }
}
