<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-2">
        <v-row no-gutters class="pl-4">
          <v-btn icon @click="close" >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-title class="justify-center flex-column">
              <v-avatar
                :color="userData.avatar ? '' : 'primary'"
                :class="userData.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="120"
                rounded
                class="mb-4"
              >
                <v-img
                  v-if="userData.avatar"
                  :src="require(`@/assets/images/avatars/${userData.avatar}`)"
                ></v-img>
                <span
                  v-else
                  class="font-weight-semibold text-5xl"
                >{{ avatarText(userData.fullname) }}</span>
              </v-avatar>

              <span class="mb-2">{{ userData.fullname }}</span>
              <div
                v-for="(role, index) of userData.roles"
                :key="role.role + String(index)"
              >
                <v-chip
                  label
                  small
                  :color="resolveUserRoleVariant(role.role)"
                  :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(role.role)}--text text-capitalize`"
                >
                  {{ roleOptions.find(rol => rol.value === role.role).text }}
                </v-chip>
              </div>
            </v-card-title>

            <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
              <div class="d-flex align-center me-8 mb-4">
                <v-avatar
                  size="40"
                  rounded
                  color="primary"
                  class="v-avatar-light-bg primary--text me-3"
                >
                  <v-icon
                    color="primary"
                    size="22"
                  >
                    {{ icons.mdiCheck }}
                  </v-icon>
                </v-avatar>

                <div>
                  <h3 class="text-xl font-weight-medium mb-n1">
                    {{ kFormatter(userData.taskDone) ? kFormatter(userData.taskDone) : 0}}
                  </h3>
                  <span>Tareas Hechas</span>
                </div>
              </div>

              <div class="d-flex align-center mb-4 me-4">
                <v-avatar
                  size="40"
                  rounded
                  color="primary"
                  class="v-avatar-light-bg primary--text me-3"
                >
                  <v-icon
                    color="primary"
                    size="22"
                  >
                    {{ icons.mdiBriefcaseVariantOutline }}
                  </v-icon>
                </v-avatar>

                <div>
                  <h3 class="text-xl font-weight-medium mb-n1">
                    {{ kFormatter(userData.projectDone) ?  kFormatter(userData.projectDone) : 0}}
                  </h3>
                  <span>Proyectos Hechos</span>
                </div>
              </div>
            </v-card-text>
          </v-col>
          <v-divider
            vertical
          ></v-divider>

          <v-col cols="12" md="6">
            <v-card-text>
              <h2 class="text-xl-center font-weight-semibold mb-2">
                Detalles
              </h2>

              <v-list>

                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium text-no-wrap me-2">Email:</span>
                  <span class="text--secondary">{{ userData.email }}</span>
                </v-list-item>

                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">Estado:</span>
                  <span class="text--secondary">
                    <v-chip
                      small
                      label
                      :color="resolveUserStatusVariant(userData.stated_at)"
                      :class="`v-chip-light-bg ${resolveUserStatusVariant(userData.stated_at)}--text font-weight-medium text-capitalize`"
                    >
                      {{ userData.stated_at ? 'Activo' : 'Inactivo' }}
                    </v-chip>
                  </span>
                </v-list-item>

                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">Rol:</span>
                  <span
                    class="text--secondary text-capitalize"
                    v-for="(role, index) of userData.roles"
                    :key="role.role"
                  >{{ userData.roles.length !== (index + 1) ?
                  roleOptions.find(rol => rol.value === role.role).text + '-' :
                  roleOptions.find(rol => rol.value === role.role).text}}
                  </span>
                </v-list-item>

                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">Contacto:</span>
                  <span class="text--secondary">{{ userData.contact }}</span>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-col>
        </v-row>

        <v-card-actions class="justify-center pt-5">
          <v-btn
            color="primary"
            class="me-3"
            outlined
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Editar
          </v-btn>
          <v-btn
            color="secondary"
            @click="close"
          >
            Retornar
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <user-bio-edit
        v-model="isBioDialogOpen"
        :user-data="userData"
        @updated="updated"
      ></user-bio-edit>
    </v-col>

  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle, mdiArrowLeft } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import UserBioEdit from './UserBioEdit.vue'
import useUsersView from '../../user-view/useUsersView'
import router from '@/router'

export default {
  components: {
    UserBioEdit,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { resolveUserStatusVariant, resolveUserRoleVariant, roleOptions } = useUsersView()

    const isBioDialogOpen = ref(false)

    const close = () => {
      router.push('/usuario/lista')
    }

    const updated = () => {
      emit('updated')
    }

    return {
      close,
      resolveUserStatusVariant,
      resolveUserRoleVariant,
      avatarText,
      kFormatter,

      isBioDialogOpen,
      roleOptions,
      updated,
      icons: {
        mdiCheck,
        mdiArrowLeft,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
