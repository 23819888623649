<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <user-bio-panel
          v-if="!isDialogVisible"
          :user-data="userData"
          @updated="updated"
        ></user-bio-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import useUsersView from '@/views/user/user-view/useUsersView'
import router from '@/router'

// eslint-disable-next-line object-curly-newline
import UserBioPanel from './user-bio-panel/UserBioPanel.vue'

export default {
  components: {
    UserBioPanel,
  },
  setup() {
    const isDialogVisible = ref(true)
    const userData = ref({})
    const { fetchOne } = useUsersView()

    const fetch = () => {
      fetchOne(router.currentRoute.params.id)
        .then(res => {
          userData.value = res
          isDialogVisible.value = false
        })
        .catch(error => console.log(error))
    }

    fetch()
    const updated = () => {
      fetch()
    }

    return {
      isDialogVisible,
      userData,
      updated,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
