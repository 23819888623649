<template>
  <!-- edit profile dialog -->
  <v-dialog
    :value="value"
    max-width="700px"
    @click:outside="$emit('input',false)"
  >
    <v-card class="user-edit-info pa-sm-5 pa-3">
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="$emit('input',false)">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-row>
      <v-card-title class="justify-center text-h5">
        Editar Información de Usuario
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form
          class="multi-col-validation"
          ref="form"
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.fullname"
                outlined
                dense
                label="Nombre Completo"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.email"
                outlined
                dense
                label="Email"
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-select
                v-model="userDataLocal.roles"
                attach
                multiple
                outlined
                :items="roleOptions"
                dense
                label="Rol"

              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.contact"
                outlined
                dense
                label="Contacto"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-switch
                v-model="userDataLocal.stated_at"
                class="mt-0"
                hide-details
              >
                <template #label>
                  <span class="font-weight-medium text--primary">{{userDataLocal.stated_at ? 'Activo' : 'Inactivo'}}</span>
                </template>
              </v-switch>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                small
                type="submit"
                :loading="loadingSubmit"
              >
                Guardar
              </v-btn>

              <v-btn
                color="secondary"
                small
                @click.prevent="$emit('input',false)"
              >
                Retornar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import useUsersView from '../../user-view/useUsersView'
import { validateForm } from '@/@core/utils'
import { required } from '@core/utils/validation'
import useAuth from '@/views/useAuth'
import { useRouter } from '@core/utils'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const form = ref(null)
    const { router } = useRouter()
    const { user } = useAuth()
    const roles = ref([])
    const loadingSubmit = ref(false)
    const userSession = JSON.parse(localStorage.getItem('userData'))
    const idSession = userSession.id

    const userDataLocal = ref({})
    userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
    const { roleOptions, update } = useUsersView()

    // on form submit
    const onSubmit = async () => {
      try {
        const isFormValid = validateForm(form)
        if (!isFormValid) return
        loadingSubmit.value = true
        let user_roles_attribute = []
        let payload = {
          id: userDataLocal.value.id,
          profile_attributes: {
            id: userDataLocal.value.profile_id,
            fullname: userDataLocal.value.fullname,
            contact: userDataLocal.value.contact,
          },
        }
        userDataLocal.value.roles.forEach(role => {
          let ele = {}
          let exist = roles.value.find(item => item.role === role)
          if (exist) {
            ele = { ...exist }
          } else {
            ele = {
              user_id: userDataLocal.value.id,
              role: role,
            }
          }
          user_roles_attribute.push(ele)
        })
        let deleted = roles.value.filter(role => {
          return !userDataLocal.value.roles.some(item => item === role.role)
        })
        if (deleted.length > 0) {
          deleted.forEach(del => {
            let ele = {
              id: del.id,
              role: del.role,
              _destroy: true,
            }
            user_roles_attribute.push(ele)
          })
        }
        payload['user_roles_attributes'] = user_roles_attribute
        let selected_role = {}
        userDataLocal.value.roles.forEach(role => {
          selected_role[role] = roleOptions.find(opt => opt.value === role).level
        })

        const values = Object.entries(selected_role).reduce((prev, curr) => {
          return prev[1] < curr[1] ? prev : curr
        })
        payload['profile_attributes'].selected_role = values[0]

        console.log(payload)
        const result = await update(payload)
        loadingSubmit.value = false
        if (result) {
          emit('updated')
          emit('input', false)
          if (idSession === userDataLocal.value.id) {
            user.value = {
              fullName: userDataLocal.value.fullname,
              id: userDataLocal.value.id,
              email: userDataLocal.value.email,
            }
            router.go(0)
          }
        }
      } catch (error) {
        loadingSubmit.value = false
      }
    }

    watch(
      () => props.value,
      val => {
        if (val === true) {
          userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
          roles.value = userDataLocal.value.roles.map(role => {
            return {
              id: role.id,
              role: role.role,
              user_id: role.user_id,
            }
          })
          userDataLocal.value['roles'] = roles.value.map(ele => ele.role)
        }
      },
    )

    return {
      form,
      loadingSubmit,
      roleOptions,
      userDataLocal,
      onSubmit,
      icons: {
        mdiClose,
      },
      validators: {
        required,
      },
    }
  },
}
</script>
